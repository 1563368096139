import React, { Suspense, lazy } from "react";
import ReactDOM from "react-dom";
import './disableConsole';
import "./index.scss";
import Spinner from "./components/spinner/spinner";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import thunk from "redux-thunk";
import { Provider } from "react-redux";
import { createStore, applyMiddleware, compose } from "redux";
import { ElectronEvents } from "./models";
import reducers from "./Store/CombineReducer";
import './polyfills/BroadcastChannelPolyfill'

import * as serviceWorker from "./serviceWorker";
import { registerFireBaseServiceWorker } from "./registerFireBaseServiceWorker";
interface BeforeInstallPromptEvent extends Event {
    readonly platforms: string[];
    readonly userChoice: Promise<{
        outcome: "accepted" | "dismissed";
        platform: string;
    }>;
    prompt(): Promise<void>;
}

declare global {
    interface Window {
        __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
        beforeinstallpromptEvent?: BeforeInstallPromptEvent | null;
        myAPI: {
            send: any;
            receive: any;
            RequestForPersistedCanned: any;
            ReturnPersistedCanned: any;
            ChildWindowUrl: any;
            PushNotification: any;
            SaveLoginCrendentialsInFile: any;
            RequestForLoginCrendentials: any;
            ReturnLoginCrendentials: any;
            saveErrorLog: any;
            PersistSettings: any;
            requestToGetPersistedSettings: any;
            getPersistedSettings: any;
            setBadgeChatCount: any;
            savAppLogs: any;
            OpenVisitorLink: any;
            SaveLicenseKey: any;
            requestToGetFireBaseToken: any;
            getFireBaseToken: any;
            pushURL: any;
            PUSH_INFO_FOR_LOG;
            UnReadChatCounter;
            MarkAsReadChat;
            CloseChildWindows;
            RequestForSettings: any;
            ReturnLanguageSettings: any;
            PersistLanguage: any;
            getFCMToken: any;
            getCurrentChat: any;
            LogoutLog: any;
            OpenEmailFormCT: any;
            showMessageBox: any;
            getDirPath: any,
            hideCustomMenu: any,
            DownloadProgress: any
        };
    }
}
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(reducers, composeEnhancers(applyMiddleware(thunk)));

const LazyAppComponent = lazy(() => import("./app/AppComponent"));
ReactDOM.render(
    <Provider store={store}>
        <Suspense fallback={<Spinner />}>
            <LazyAppComponent />
        </Suspense>
    </Provider>,
    document.getElementById("root")
);

// serviceWorker.unregister();
registerFireBaseServiceWorker();
serviceWorker.register();
