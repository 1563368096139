export const register = () => {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker
      .register('firebase-messaging-sw.js')
      .then(function (registration) {
        // console.log("firebase-messaging-sw.js")
        return registration.scope;
      })
      .catch(function (err) {
        return err;
      });
  }

  if ('serviceWorker' in navigator) {
    // Global service worker
    navigator.serviceWorker
      .register('service-worker.js')
      .then((registration) => {
        // console.log('Service Worker registered with scope:', registration.scope);
      })
      .catch((error) => {
        console.error('Service Worker registration failed:', error);
      });
  }
};


export function unregister() {
  if ("serviceWorker" in navigator) {
     navigator.serviceWorker.ready.then(registration => {
        registration.unregister();
     });
  }
}